<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <b-img :src="require('@/assets/images/logo/128.png')" width="128" height="128" />
        </b-link>

        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your password
        </b-card-text>

        <b-alert
          variant="danger"
          :show="isShow"
          dismissible
        >
          <div class="alert-body">
            <span>{{this.errMsg}}</span>
          </div>
        </b-alert>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Send reset link
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BAlert, BImg
} from 'bootstrap-vue'
import { required, email } from '@validations'

import RepositoryFactory from '../api/RepositoryFactory'
const auth = RepositoryFactory.get('auths')
import router from '@/router'


export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BImg,
  },
  data() {
    return {
      userEmail: '',
      isShow: false,
      errMsg: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.isShow = false
      this.errMsg = ""
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          auth.forgotPassword({email: this.userEmail}).then(rs=>{
             if(rs.response && rs.response.data.success === false){
              this.errMsg = rs.response.data.errMsg
              this.isShow = true
            }else{
              this.$swal({
                title: 'Success',
                text: 'Your confirm email has been sent, Please check your email',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(rs=>{
                router.push('/login')
              })
            }
          }).catch(e=>{
            console.log(e)
           
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
